import React, { useEffect, useState,useContext } from "react";
import { fetchAllInfluencers, GetInfl_Info } from "../services/auth-service/InflAccountService";
import { fetchAllClients } from "../services/auth-service/clientAccountService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import UserDashBoard from "./UserDashBoard";
import { Link,useNavigate } from "react-router-dom";
import InfluencerProfile from "./InfluencerProfile";
import InfluencerRequestDetails from "./InfluencerRequestDetails";
import styles from "../pages/influencerAbout/influencerComponents/influenceredit.module.css";
import AllRequests from "./AllRequests";
import { UserContext } from '../App';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [influencers, setInfluencers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("Influencer");
  const [currentPage, setCurrentPage] = useState(1);
  const {state,dispatch} = useContext(UserContext);
  const influencersPerPage = 5;
  const [showOverlay, setShowOverlay] = useState(false);
  const [editedInfluencer, setEditedInfluencer] = useState(null);
  const [showPopupForm, setShowPopupForm] = useState(false);
  const [showAllRequest, setShowAllRequest] = useState(false);
  const [influencerId, setInfluencerId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetInfl_Info();
        if (!response || response.status !== 200) {
          
          dispatch({ type: 'USER', payload: false });
          navigate('/');
        } else if (response.status === 401) {
          dispatch({ type: 'USER', payload: false });
          navigate('/');
        } else if (response.status === 200) {
          dispatch({ type: 'USER', payload: true });
          const responseInfluencers = await fetchAllInfluencers();
          setInfluencers(responseInfluencers);
          const responseUsers = await fetchAllClients();
          setUsers(responseUsers);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleToggleOverlay = (influencer) => {
    setShowOverlay(!showOverlay);
    setEditedInfluencer(influencer);
  };

  const handleOpenPopupForm = (id) => {
    setShowPopupForm(true);
    setInfluencerId(id);
  };
  const handleOpenAllRequests=()=>{
    setShowAllRequest(true)
  }

  const handleClosePopupForm = () => {
    setShowPopupForm(false);
  };
  const handleAllRequest=()=>{
    setShowAllRequest(false)
  }

  const filteredList = selectedOption === "Influencer" ? influencers : users;

  const filteredData = filteredList.filter((item) =>
    item.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Instagram?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Facebook?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Twitter?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Youtube?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastInfluencer = currentPage * influencersPerPage;
  const indexOfFirstInfluencer = indexOfLastInfluencer - influencersPerPage;
  const currentInfluencers = filteredData.slice(
    indexOfFirstInfluencer,
    indexOfLastInfluencer
  );

  const totalPages = Math.ceil(filteredData.length / influencersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="text-center" style={{ marginTop: "100px" }}>
        <div className="inflanar-profile-info mg-top-30">
          <div className="inflanar-profile-info__head">
            <h3 className="inflanar-profile-info__heading">
              {selectedOption === "Influencer" ? "Admin Dashboard" : "User Dashboard"}
            </h3>
          </div>
          <div className="dropdown float-left">
            <button
              className="inflanar-btn inflanar-btn--search dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {selectedOption}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className={`dropdown-item ${
                  selectedOption === "Influencer" ? "active" : ""
                }`}
                href="#"
                onClick={() => handleOptionClick("Influencer")}
              >
                Influencer
              </a>
              <a
                className={`dropdown-item ${
                  selectedOption === "User" ? "active" : ""
                }`}
                href="#"
                onClick={() => handleOptionClick("User")}
              >
                User
              </a>
            </div>
            <button
                          className={`btn btn-outline-primary ml-2 ${styles.buttonsize}`}
                          onClick={() => handleOpenAllRequests()}
                          style={{zIndex:"1"}}
                        >
                          All Sessions
              </button>
          </div>
          <div className="">
            <div
              className="inflanar-search-form inflanar-search-form__hero mg-top-10 aos-init aos-animate mg-btm-20 "
              style={{
                width: "410px",
                marginLeft: "auto",
              }}
            >
              <div className="inflanar-search-form__form">
                <div className="inflanar-search-form__group">
                  <div className="inflanar-search-form__icon">
                    <FontAwesomeIcon
                      style={{ fontSize: "15px" }}
                      data-toggle="collapse"
                      icon={faSearch}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedOption==="Influencer"?<div className="card" style={{overflowY:"auto",maxHeight:"100%"}}>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Email</th>
                    <th>Category</th>
                    <th>Instagram</th>
                    <th>Facebook</th>
                    <th>Twitter</th>
                    <th>YouTube</th>
                    <th>OTP_Instagram</th>
                    <th>OTP_Facebook</th>
                    <th>OTP_Twitter(X)</th>
                    <th>OTP_Youtube</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentInfluencers.map((item, index) => (
                    <tr key={index}>
                      <td>{item.firstName} {item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{item.category}</td>
                      <td>{item.Instagram ? item.Instagram : ""}</td>
                      <td>{item.Facebook ? item.Facebook : ""}</td>
                      <td>{item.Twitter ? item.Twitter : ""}</td>
                      <td>{item.Youtube ? item.Youtube : ""}</td>
                      <td>{item.instaResetOtp ? item.instaResetOtp.otp : ""}</td>
                      <td>{item.facebookResetOtp ? item.facebookResetOtp.otp : ""}</td>
                      <td>{item.twitterResetOtp ? item.twitterResetOtp.otp : ""}</td>
                      <td>{item.youtubeResetOtp ? item.youtubeResetOtp.otp : ""}</td>
                      <td>                 
                        <button
                          className={`btn btn-outline-info ${styles.buttonsize}`}
                          onClick={() => handleToggleOverlay(item)}
                        >
                          Profile
                        </button>
                        
                        <button
                          className={`btn btn-outline-primary mt-2 ${styles.buttonsize}`}
                          onClick={() => handleOpenPopupForm(item._id)}
                        >
                          Sessions
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination Section */}
            <div className="mt-2">
              <div className="text-muted" style={{ float: "left" }}>
                Page {currentPage} of {totalPages}
              </div>
              <nav>
                <ul className="pagination justify-content-end">
                  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                    <button className="page-link " onClick={() => paginate(currentPage - 1)}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <li
                      key={index}
                      className={`page-item ml-2 ${currentPage === index + 1 ? "active" : ""}`}
                    >
                      <button className="page-link" onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                    <button className="page-link ml-2" onClick={() => paginate(currentPage + 1)}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>          
          </div>:
          <UserDashBoard values={filteredData}/>
          }
        </div>
      </div>
      {/* Overlay Components */}
      {showOverlay && (
        <div className="overlay">
          <InfluencerProfile initialValues={editedInfluencer} onClose={handleToggleOverlay} />
        </div>
      )}
      {showPopupForm && (
        <div className="overlay mw-100">
          <InfluencerRequestDetails initialValues={influencerId} onClose={handleClosePopupForm} />
        </div>
      )}
      {showAllRequest && (
        <div className="overlay mw-100">
          <AllRequests  onClose={handleAllRequest} />
        </div>
      )}
    </>
  );
};

export default AdminDashboard;

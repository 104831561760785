import './App.css';
import React,{createContext,useReducer} from 'react'
import {Route,Routes} from 'react-router-dom';
import  Home  from './pages/home/Home';
import Navbar from './components/shared/navigation/Navbar';
import SignIn from './pages/authentication/SignIn';
import RadioValidationForm from './pages/authentication/RadioValidationForm';
import SignUp from './pages/authentication/signup/SignUp';
import Footer from './components/shared/footer/Footer';
import Faq from './pages/faq/Faq';
import ContactUs from './pages/contactUs/ContactUs';
import InfluencerAbout from './pages/influencerAbout/InfluencerAbout';
import Errorpage from './pages/Errorpage';
import ClientAbout from './pages/clientAbout/ClientAbout';
import Logout from './pages/authentication/Logout';
import {reducer,initialState} from '../src/reducer/UseReducer'
import Forgotpassword from './pages/forgotpassword/Forgotpassword';
import OTPVerify from './pages/forgotpassword/OTPVerify';
import ChangeFgtPawd from './pages/forgotpassword/ChangeFgtPawd';
import TermsAndCondition from './pages/termsAndCondition/TermsAndCondition';
import PrivacyAndPolicy from './pages/privacyAndPolicy/PrivacyAndPolicy';
import CheckAvailabilty from './pages/clientAbout/bookInfluencer/CheckAvailabilty';
import MakePayment from './pages/clientAbout/bookInfluencer/MakePayment';
import CancellationPage from './pages/cancellationPage/CancellationPage';
import AdminDashboard from './adminDashboard/AdminDashboard';


export const UserContext = createContext();

function App() {
   const [state, dispatch] = useReducer(reducer, initialState)
   const hostname = window.location.hostname;
   console.log(hostname);
   if(hostname === 'localhost' || hostname === 'www.inkorero.com' ||  hostname === 'inkorero.com'){
    // do nothing
 } else {
    window.location.href = 'https://www.google.com'
 }
  return (
    <UserContext.Provider value={{state,dispatch}}>
    <div className="alpha-app-content">
    <Navbar />
    <div className="main-content-area">
      <Routes>
      <Route exact path="/"  element={<Home />} /> 
      <Route path="/signin" element={<SignIn />}/>
      <Route path='/influencervalidationform' element={<RadioValidationForm />}/>
      <Route path="/auth/signup" element={<SignUp />}/>
      <Route path="/influencerhome" element={<InfluencerAbout />}/>
      <Route path="/clienthome" element={<ClientAbout />}/>
      <Route path='/faqs' element={<Faq />}/>
      <Route path="/contactus" element={<ContactUs />}/>
      <Route path="/logout" element={<Logout />}/>
      <Route path='/forgotpassword' element={<Forgotpassword />}/>
      <Route path='/otpverify' element={<OTPVerify /> }/>
      <Route path='/changeforgotpassword' element={<ChangeFgtPawd />}/>
      <Route path='/terms-condition' element={<TermsAndCondition />} />
      <Route path='/privacyandpolicy' element={<PrivacyAndPolicy />} />
      <Route path="/checkavailablty" element={<CheckAvailabilty />}/>
      <Route path="/cancellationpage" element={<CancellationPage />} />
      <Route path="/makepayment" element={<MakePayment />}/>
      <Route path="/admin1953" element={<AdminDashboard />}/>

      <Route path="*" element={<Errorpage />} />
      </Routes>
    </div>
    <Footer />
    </div>
    </UserContext.Provider>
  );
}

export default App;

import HttpService from "../http-service/HttpService";

 //  /api/acesstoken
const accesToken = async (_id)=>{ 
  try{
    const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/acesstoken`,{_id:_id})
      return response;
    } catch (error) {
      console.error('Error fetching Influencer requests:', error);
      if(error.response.status===404){
        return 404;
      }
    }
}

export {accesToken}
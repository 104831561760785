// registerclient

import HttpService from "../http-service/HttpService";

//To register client
async function RegisterClient(registerdata){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/registerclient`,registerdata)
        return data;
    } catch (error) {
        if(error.response.status===409){
            return 409;
        }
         console.log(error);
    }
}


//To Login CLient
async function LoginClient(loginInfo){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/client/signin`,loginInfo,{ withCredentials: true })
        return data;

    } catch (error) {
        if(error.response.status===400){
            return 400;
        }
        console.log(error);
    }
}

//To Get Client Info 
async function GetClient_Info(){
    try {
        const response =await HttpService.get(`${process.env.REACT_APP_API_URL}/api/client/home`,{
            method:"GET",
            headers:{
              Accept:"application/json",
              "Content-Type":"application/json"
            },
            credentials:"include"
          });
          return response;
    } catch (error) {
        if(error.response.status===401){
            return 401;
          }
           throw error;
        // console.log(error);
    }
}

//To Update Client profile
const updateClient = async(formData)=>{
    try {
        const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/updateClient`, formData);
        return response;
        
      } catch (error) {
        if(error.response.status===404){
            return 404;
        }else if(error.response.status===400){
            return 400;
        }
        console.error('Error during user update:', error);
      }
    }

//Forgot Client
async function forgotClient(forgotmail){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/forgot-password-client`,forgotmail)
        return data;
  
    } catch (error) {
        if(error.response.status===400){
            return 400;
        }
        console.log(error);
    }
  
  }
  
  //OTP Client
  async function OTPClient(forgotOtp){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/otp-verify-client`,forgotOtp)
        return data;
  
    } catch (error) {
        if(error.response.status===400){
            return 400;
        }
        console.log(error);
    }
  
  }
  
  //change Password via otp
  async function PaswwordViaOTPClient(forgotOtp){
    try {
        const data = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/reset-password-client`,forgotOtp)
        return data;
  
    } catch (error) {
        if(error.response.status===400){
            return 400;
        }
        console.log(error);
    }
  
  }

//Email Verify Client
async function VerifyEmailClient(email){
    try {
      const response = await HttpService.put(`${process.env.REACT_APP_API_URL}/api/update-verify-email-client`,{ email });
      return response;
  
    } catch (error) {
        if(error.response.status===404){
            return 404;
        }
        console.log(error);
    }
  }

//fetch All client
const fetchAllClients = async (page) => {
    try {
      const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/getallclients`);
      return response.data; // Return the data from the response
    } catch (error) {
      console.error('Error fetching clients:', error);
      throw error; // Throw the error to handle it in the calling function
    }
  };


// search all clients /api/searchclientsdebounce
const AllSearchClientDebounce = async (searchQuery,page) => {
    try {
        const response = await HttpService.get(`${process.env.REACT_APP_API_URL}/api/searchclientsdebounce?search=${searchQuery}&page=${page}`);  //   setInfluencers(response.data.influencers);

        return response;
        } catch (error) {
          console.error('Error fetching influencers:', error);
          // Handle the error
        }
  }

export {RegisterClient,LoginClient,GetClient_Info,updateClient,
    PaswwordViaOTPClient,OTPClient,forgotClient,VerifyEmailClient,fetchAllClients,AllSearchClientDebounce};
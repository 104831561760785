import React, { useState, useEffect } from "react";
import {
  fetchAllRequestInfluencer,
  handleUpdateRequest,
} from "../services/auth-service/requestService";
import AlertMessage from "../components/shared/alertMessage/AlertMessage";
import styles from "./clientedit.module.css";
const status = ["pending", "accepted", "completed", "decline", "markcompleted"];
const paymentStatus =['Remittance pending','Payment received','Requesting refund','Refunded','Remitted'];
const InfluencerRequestDetails = ({ initialValues, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alertData, setAlertData] = React.useState(null);
  const [requests, setRequests] = useState([]);

  const [editedData, setEditedData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const ClientRequests = async (initialValues, page) => {

    try {
      const response = await fetchAllRequestInfluencer(initialValues, page);

      if (!response.status === 200) {
        setAlertData({
          message: "failed to fetch Requests",
        });
      } else if (response === 404) {
        setAlertData({
          message: "No sessions for now!!",
        });
      }
      setRequests(response.data.requests);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  // handleUpdateRequest(_id,status)
  useEffect(() => {
    // Fetch data when the component mounts or when currentPage changes
    ClientRequests(initialValues, currentPage);
  }, [currentPage, initialValues]);
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];

    const renderEllipsis = () => <span className="ml-2 mt-2 mb-2">...</span>;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
        // Display first, last, and nearby pages
        buttons.push(
          <button
            className={`btn btn-outline-primary mt-2 ml-2 mb-2 ${
              i === currentPage ? "active" : ""
            }`}
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        );
      } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
        // Display ellipsis if not already displayed
        buttons.push(renderEllipsis());
      }
    }

    return buttons;
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    const initialEditedData = requests.map((req) => ({ ...req }));
    setEditedData(initialEditedData);
  };

  const handleSave =async (_id,status,paymentStatus,index) => {
    try{
    const response  =  await handleUpdateRequest(_id,status,paymentStatus)
    const editedRowData = editedData[index];

    const updatedRequests = [...requests];
    updatedRequests[index] = editedRowData;
    setRequests(updatedRequests);

    setEditIndex(null);
    }catch(e){
      console.log(e);
    }
  };
  const handleDelete = (index) => {
    const updatedRequests = [...requests];
    updatedRequests.splice(index, 1);
    setRequests(updatedRequests);
  };

  return (
    <>
      {/* <div className="inflanar-profile-info mg-top-30"> */}
      <div className="container mt-5">
        <div className="card">
          <span
            className={`btn btn-outline-dark close-btn ${styles.closebutton}`}
            onClick={onClose}
          >
            &times;
          </span>
          <div className="card-header">
            <h2 className="mb-0">
              Edit your Influencer Request Session Details
            </h2>
          </div>
          <div className="card-body">
            {/* <div className="card mw-100"> */}
            {/* <div className="table"> */}
            <table className="table table-stripped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>RozarPay Order ID</th>
                  <th>RozarPay Payment ID</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((req, index) => (
                  <tr key={index}>
                    <td>
                     {/* {editIndex === index ? (
                        <input
                          type="text"
                          value={`${editedData[index].client.firstName} ${editedData[index].client.lastName}`}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            const [firstName, lastName] =
                              e.target.value.split(" ");
                            newEditedData[index].client.firstName = firstName;
                            newEditedData[index].client.lastName = lastName;
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        `${req.client.firstName} ${req.client.lastName}`
                      )} */}
                      {`${req.client.firstName} ${req.client.lastName}`}
                    </td>
                    <td>
                     {/*} {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].razorpay_order_id}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              razorpay_order_id: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.razorpay_order_id
                      )} */}
                      {req.razorpay_order_id}
                    </td>
                    <td>
                     {/*} {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].razorpay_payment_id}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              razorpay_payment_id: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.razorpay_payment_id
                      )} */}
                      {req.razorpay_payment_id}
                    </td>
                    <td>
                     {/* {editIndex === index ? (
                        <input
                          type="text"
                          value={editedData[index].amount}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              amount: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        />
                      ) : (
                        req.amount
                      )} */}
                      {req.amount}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <select
                          className="form-control"
                          value={editedData[index].status}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              status: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        >
                          {status.map((statusOption, optionIndex) => (
                            <option key={optionIndex} value={statusOption}>
                              {statusOption}
                            </option>
                          ))}
                        </select>
                      ) : (
                        req.status
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <select
                          className="form-control"
                          value={editedData[index].paymentStatus}
                          onChange={(e) => {
                            const newEditedData = [...editedData];
                            newEditedData[index] = {
                              ...newEditedData[index],
                              paymentStatus: e.target.value,
                            };
                            setEditedData(newEditedData);
                          }}
                        >
                          {paymentStatus.map((statusOption, optionIndex) => (
                            <option key={optionIndex} value={statusOption}>
                              {statusOption}
                            </option>
                          ))}
                        </select>
                      ) : (
                        req.paymentStatus
                      )}
                    </td>
                    <td>
                      {editIndex === index ? (
                        <React.Fragment>
                          <button
                            className="btn btn-info"
                            onClick={() => handleSave(editedData[index]._id,editedData[index].status,editedData[index].paymentStatus,index)}
                          >
                            Save
                          </button>
                        </React.Fragment>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEdit(index)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="text-right">
            <button
              className="btn btn-outline-primary mt-2 mb-2"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            {renderPageButtons()}
            <button
              className="btn btn-outline-primary mt-2 ml-2 mb-2 mr-2"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next Page
            </button>
          </div>
        </div>
      </div>

      {alertData && (
        <div className="overlay">
          {/* You can add an overlay to dim the background if needed */}
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  );
};

export default InfluencerRequestDetails;

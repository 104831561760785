import React from 'react'
import styles from './BookInfluencer.module.css'; 
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner,faCircleCheck } 
from "@fortawesome/free-solid-svg-icons";


const BookInfluencer = ({ clientdata, influencerdata,onClose}) => {
    const navigate = useNavigate();

    let data = {
      clientdata:clientdata,
      influencerdata:influencerdata
    }
  const navigatetoAvailabilty = (recieved)=>{
    data ={
      ...data,
      type:recieved
    }
    navigate('/checkavailablty',{ state: { data:data } });
  }
    return (
        <div className="container" style={{ maxWidth: '600px',overflowY: "auto",maxHeight: "80%" }}>
        <div className={`card ${styles.influencerCard}`}>
        <span className={`btn btn-outline-dark close-btn ${styles.closebutton}`} onClick={onClose}>&times;</span>
          <div className="card-body">
            {/* User Information */}
  <div className="row">
    <div className="">
      <h5>User's Information</h5>
    </div>
    <div className="col ml-auto">
      <p>
        <strong>First Name:</strong> {clientdata.firstName}
      </p>
      <p>
        <strong>Last Name:</strong> {clientdata.lastName}
      </p>
    </div>
  </div>
            {/* Influencer Information */}
            <div className="mt-4">
              <h5>Influencer's Information</h5>
              <p>
                <strong>First Name:</strong> {influencerdata.firstName}
              </p>
              <p>
                <strong>Last Name:</strong> {influencerdata.lastName}
              </p>
              <p>
                <strong>Category of Influence:</strong> {influencerdata.category}
              </p>
            </div>
    
            {/* About the Influencer */}
            <div className="mt-4">
              <h5>About the Influencer</h5>
              <p>{influencerdata.Intro}</p>
            </div>
    
            {/* Social Media Links */}
            <div className="mt-4">
              <h5>Social Media</h5>
              <div className="row">
                {influencerdata.Instagram && (
                  <div className="col-md-6">
                    <p>
                      <strong>Instagram:</strong> <br />
                       {influencerdata.Instagram}{influencerdata.verifyInstagram &&(<span className={styles.verifiedTick}><FontAwesomeIcon
                        className=""
                        style={{marginRight:"-6px", marginLeft:"5px"}}
                        icon={faCircleCheck}
                      /></span>)}
                    </p>
                  </div>
                )}
                {influencerdata.Facebook && (
                  <div className="col-md-6">
                    <p>
                      <strong>Facebook:</strong> <br />
                      {influencerdata.Facebook}{influencerdata.verifyFacebook&&(<span className={styles.verifiedTick}><FontAwesomeIcon
                        className=""
                        style={{marginRight:"-6px", marginLeft:"5px"}}
                                                icon={faCircleCheck}
                      /> </span>)}
                    </p>
                  </div>
                )}
                {influencerdata.Twitter && (
                  <div className="col-md-6">
                    <p>
                      <strong>X(Twitter):</strong> <br />
                       {influencerdata.Twitter}{influencerdata.verifyTwitter &&(<span className={styles.verifiedTick}><FontAwesomeIcon
                        className=""
                        style={{marginRight:"-6px", marginLeft:"5px"}}
                                                icon={faCircleCheck}
                      /> </span>)}
                    </p>
                  </div>
                )}
                {influencerdata.Youtube && (
                  <div className="col-md-6">
                    <p>
                      <strong>YouTube:</strong> <br />
                       {influencerdata.Youtube}{influencerdata.verifyYoutube &&(<span className={styles.verifiedTick}><FontAwesomeIcon
                        className=""
                        style={{marginRight:"-6px", marginLeft:"5px"}}
                                                icon={faCircleCheck}
                      /> </span>)}
                    </p>
                  </div>
                )}
              </div>
            </div>
    
            {/* Fee Information */}
            <div className="mt-4">
              <h5>Fee</h5>
              <p>
                <strong>Fee for single session:</strong>Rs {influencerdata.amountforhalf} 
              </p>
              <p>
                <strong>Fee for group session:</strong>Rs {influencerdata.amountforfull} 
              </p>
            </div>
    
            {/* Booking Buttons */}
            <div className="mt-4">
              <button className={`btn btn-primary mr-2 ${styles.buttonsize}`} onClick={()=>navigatetoAvailabilty("Single")}>Book One on One</button>
              <button className={`btn btn-success ${styles.buttonsize}`} onClick={()=>navigatetoAvailabilty("Group")}>Attend Group Session</button>
            </div>
          </div>
        </div>
        </div>
      );
    
}

export default BookInfluencer;
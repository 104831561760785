import React,{useState} from 'react'
import Editprofile from './Editprofile';
import MarkFreeSlots from '../../markFreeCalender/MarkFreeSlots';
import { useNavigate,useLocation } from "react-router-dom";

import styles from './influenceredit.module.css'
import { forgotInfluencer } from '../../../services/auth-service/InflAccountService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import {subjectEmail,emailMessage,subjectsocialEmail,socialEmailMessage} from '../../../utils/common'
import EditMarkedSlot from '../../markFreeCalender/EditMarkedSlot';

const Profile = ({initialValues}) => {
    const navigate = useNavigate();
    const [showOverlay, setShowOverlay] = useState(false);
    const [showOverlayTime, setShowOverlayTime] = useState(false);
    const [showOverlayBooked,setShowOverlayBooked] = useState(false);
        const [values] = useState(initialValues);
    const [inputdata] = useState({
        email:values.email,
        subject:subjectEmail,
        message: emailMessage
     })
     const [socialMessage] = useState({
        email:values.email,
        subject:subjectsocialEmail,
        message: socialEmailMessage
     })
    const [alertData, setAlertData] = useState(null);
    const handleToggleOverlay = () => {
        setShowOverlay(!showOverlay);
      };
      const handleTimeToggleOverlay = () => {
        setShowOverlayTime(!showOverlayTime);
      };
      const handleSlotBookedOverlay=()=>{
        setShowOverlayBooked(!showOverlayBooked)
      }
      const data = {
        ...inputdata,
        toggler:"influencer"
    }

    const sendVerifyEmail =async (sharedata,social)=>{
      if(social){
        setAlertData({
          message: 'An OTP has been sent to your registered email. To initiate the verification process, please follow the instructions in the email and share the OTP.',
        });
      }
       const res = await forgotInfluencer(sharedata,social)
       if(res.status === 400 || !res ){
        setAlertData({
          message: 'Invalid User!!',
        });
     }else if(res.status===200 && !social){
       navigate('/otpverify',{ state: { data: data } })
     }
    }
    
  return (
    <>
    <div className={`${styles.paralleldivs} inflanar-profile-info mg-top-30 `}>
    <div className='row'>
    <div className="col-lg-9 col-12">  
<div className="inflanar-profile-info__head">
<div className='d-flex'>
<h3 className="inflanar-profile-info__heading">Personal Information</h3>
</div>
</div>
<ul className={`inflanar-profile-info__list inflanar-dflex-column list-none ${styles.profilelength}`}>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Name :</h4>
<p className="inflanar-profile-info__text">{values.firstName} {values.lastName}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Email :</h4>
<p className="inflanar-profile-info__text">{values.email}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Phone :</h4>
<p className="inflanar-profile-info__text">{values.phone}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Category :</h4>
<p className="inflanar-profile-info__text">{values.category}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Gender :</h4>
<p className="inflanar-profile-info__text">{values.gender}</p>
</li>
<li className={`inflanar-dflex ${styles.profiledisplay}`}>
<h4 className="inflanar-profile-info__title">Introduction :</h4>
<p className="inflanar-profile-info__text">{values.Intro}</p>
</li>
</ul>
</div>
<div className='ml-auto col-lg-3 col-12 mt-3 mt-md-0'>
<button className={`btn btn-outline-info ${styles.buttonsize}`}
 onClick={handleToggleOverlay}>Edit Profile</button>
 <br />
 {(<button className={`btn btn-outline-info mt-2 ${styles.buttonsize}`}
 onClick={()=>sendVerifyEmail(inputdata)} disabled={values.verifyEmail}>Verify Email {values.verifyEmail}</button>)}
 <br />
 <button className={`btn btn-outline-info mt-2 ${styles.buttonsize}`}
 onClick={handleTimeToggleOverlay}>Mark Your Calendar</button>
 <br/>
 <button className={`btn btn-outline-info mt-2 ${styles.buttonsize}`}
 onClick={handleSlotBookedOverlay}>Edit Calendar Slot</button>
 <div className="row">
 {values.Instagram && (
   <div className={`col-12 ${styles.verticalButton}`}>
     <button
       className={`btn btn-outline-info mt-2 ${styles.buttonsize} `}
       onClick={() => sendVerifyEmail(socialMessage, 'socialInsta')}
       disabled={values.verifyInstagram}
     >
       Verify Instagram
     </button>
   </div>
 )}

 {values.Facebook && (
   <div className={`col-12 ${styles.verticalButton}`}>
     <button
       className={`btn btn-outline-info mt-2 ${styles.buttonsize} `}
       onClick={() => sendVerifyEmail(socialMessage, 'socialFacebook')}
       disabled={values.verifyFacebook}
     >
       Verify Facebook
     </button>
   </div>
 )}

 {values.Twitter && (
   <div className={`col-12 ${styles.verticalButton}`}>
     <button
       className={`btn btn-outline-info mt-2 ${styles.buttonsize} `}
       onClick={() => sendVerifyEmail(socialMessage, 'socialTwitter')}
       disabled={values.verifyTwitter}
     >
       Verify X (Twitter)
     </button>
   </div>
 )}

 {values.Youtube && (
   <div className={`col-12 ${styles.verticalButton}`}>
     <button
       className={`btn btn-outline-info mt-2 ${styles.buttonsize} `}
       onClick={() => sendVerifyEmail(socialMessage, 'socialYoutube')}
       disabled={values.verifyYoutube}
     >
       Verify YouTube
     </button>
   </div>
 )}
</div>


</div>
</div>
</div>
{showOverlay && (
    <div className='overlay'>
    <Editprofile initialValues={values} onClose={handleToggleOverlay}/></div>
  )}
  {showOverlayTime && (
    <div className='overlay'>
    <MarkFreeSlots influencerId={values._id} onClose={handleTimeToggleOverlay}/></div>
  )}
  {showOverlayBooked && (
    <div className='overlay'>
    <EditMarkedSlot influencerId={values._id} onClose={handleSlotBookedOverlay}/></div>
  )}
  {alertData && (
    <div className="overlay">
      {/* You can add an overlay to dim the background if needed */}
      <AlertMessage
        message={alertData.message}
        type={alertData.type}
        onClose={() => setAlertData(null)}
      />
    </div>
  )}
    </>
  )
}

export default Profile
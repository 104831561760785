import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import { useNavigate } from "react-router-dom";
import {  mailbody, mailbodyAcceptance, mailbodyForInf } from '../../../utils/common';
import styles from './BookInfluencer.module.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } 
from "@fortawesome/free-solid-svg-icons";
import { confirmMail } from '../../../services/auth-service/requestService';
import moment from 'moment'; // Import moment library for date formatting
import { accesToken } from '../../../services/auth-service/teamsAPI';

const CheckAvailabilty = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state && location.state.data;
  const [alertData, setAlertData] = React.useState(null);

  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [slotsPerPage] = useState(10);
  const [purpose,setpurpose] = useState()
  const [loading, setLoading] = useState(false);
  let amountfee;
  // Calculate total amount
  if(data.type==='Single'){
    amountfee = data.influencerdata.amountforhalf;
  }else{
    amountfee = data.influencerdata.amountforfull;
  }
  let gstRate = (data.influencerdata.platformfee*18)/100
  const totalAmount = amountfee + data.influencerdata.platformfee + gstRate
  let Charge = (totalAmount*2.36)/100;
  let RazorpayCharge= Math.round(Charge * 100) / 100;
  useEffect(() => {
    const fetchTimeSlots = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/influencertimeslot?influencerId=${data.influencerdata._id}&type=${data.type}`
        );
        // Sort slots by date first, then by startTime for the same date
      const sortedSlots = response.data.sort((a, b) => {
          // Compare dates (a.date and b.date)
          const dateComparison = new Date(a.date) - new Date(b.date);  
          // If dates are the same, compare start times (a.startTime and b.startTime)
          if (dateComparison === 0) {
            // Extract the start times from each slot
            const startTimeA = a.startTime;
            const startTimeB = b.startTime;
            // Compare start times using string comparison
            if (startTimeA < startTimeB) {
              return -1; // A should come before B
            } else if (startTimeA > startTimeB) {
              return 1; // A should come after B
            } else {
              return 0; // A and B are the same
            }
          }
          return dateComparison; // Return result of date comparison
      });
        // Calculate the date and time 72 hours from now
      const now = new Date();
      const threeDaysFromNow = new Date(now.getTime() + 48 * 60 * 60 * 1000);
      
      // Filter slots to only include those at least 72 hours in the future
      const filteredSlots = sortedSlots.filter(slot => {     
        const dateOnly = slot.date.split('T')[0]     
        const currentNewTime = `${dateOnly}T${slot.startTime}:00`
        return new Date(currentNewTime) > threeDaysFromNow});
      setTimeSlots(filteredSlots);
      } catch (error) {
        console.error('Error fetching time slots:', error);
      }
    };
    fetchTimeSlots();
  }, [data.influencerdata._id, data.type]);

  const indexOfLastSlot = currentPage * slotsPerPage;
  const indexOfFirstSlot = indexOfLastSlot - slotsPerPage;
  const currentSlots = timeSlots.slice(indexOfFirstSlot, indexOfLastSlot);

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e)=>{
    e.preventDefault();
    setpurpose(e.target.value)
  }

  const handleProceedToPayment = async () => {
    try {
      const { data: { key } } = await axios.get(`${process.env.REACT_APP_API_URL}/api/getkey`);
      const { data: { order } } = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkout`, {
        amount: totalAmount,
      });

      const options = {
        key: key, 
        amount: order.amount,
        currency: "INR",
        name: "Inkorero",
        description: "Transaction for Influencer",
        image: "img/Social-56.png",
        order_id: order.id,
        handler: async function (response) {
          try {
            const mailAcceptance = mailbodyAcceptance({razorpay_order_id: response.razorpay_order_id});
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/paymentverification`, {
              InfluencerId: data.influencerdata._id,
              clientId: data.clientdata._id,
              selectedSlot: selectedSlot,
              slotid: selectedSlot._id,
              amount: totalAmount,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              mailAcceptance:mailAcceptance,
              purpose:purpose,
              platformfee:data.influencerdata.platformfee
            });
            setLoading(true); 
            if (res.status === 201) {
              const email = data.clientdata.email;
              const receivedData = mailbody(res.data.request, amountfee, gstRate, data.influencerdata.platformfee,RazorpayCharge);
              const receivedDataInf = mailbodyForInf(res.data.request, amountfee, gstRate, data.influencerdata.platformfee,RazorpayCharge);
              // await accesToken(res.data.request._id)
              await confirmMail({ ...receivedData, email });
              await confirmMail({ ...receivedDataInf, email: data.influencerdata.email });
              setLoading(false);
              navigate('/makepayment');
            } else {
              throw new Error('Client data does not meet specific condition');
            }
          } catch (error) {
            console.error('Error in handler axios.post:', error);
          }
        },
        prefill: {
          name: "Mohd Aasim",
          email: "aasimwatch@gmail.com",
          contact: "9000090000"
        },
        notes: {
          address: "Razorpay Corporate Office"
        },
        theme: {
          color: "#FE2C55"
        }
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error('Error creating request:', error);
    }
  };
   // Render loading spinner if loading is true
   if (loading) {
    return (// Display spinner while waiting for the response
    <div className="spinner-container">
    <FontAwesomeIcon
                    className="spinner"
                    icon={faSpinner}
                  />
                  </div>)
  }

  return (
    <>
      <div className="container mt-5">
        <h2>Book Influencer</h2>
        <p className='text-danger mb-3' style={{ fontSize: "100%" }}>**We are offering a trial period for free session bookings. Select  any payment mode, no money will be deducted as our payment gateway is in test mode. Sessions will be scheduled without any charges.**</p>
        
        {timeSlots.length > 0 ? (
          <>
            <p className='mb-3'>Select a time slot to proceed with the booking:</p>
            <div className="row">
              {currentSlots.map((slot) => (
                <div key={slot._id} className="col-md-4 mb-3">
                  <div
                    className={`card ${selectedSlot === slot ? 'bg-primary text-white' : ''}`}
                    onClick={() => handleSlotSelect(slot)}
                  >
                    <div className="card-body">
                      <h5 className="card-title">{slot.startTime} - {slot.endTime}</h5>
                      <h6 className="card-text">{moment(slot.date).format('DD/MM/YYYY')} 
                      </h6>
                      <br></br>
                      {data.type === 'Group' && (
                        <span style={{ fontSize: "70%" }}>Remaining {slot.participants}</span>
                      )}{/* Format date as dd/mm/yyyy */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {selectedSlot &&(
              <>
              <div style={{padding:"16px"}}>
                <textarea
                type="text"
                className="mb-1"
                name="message"
                id={styles.inp}
                placeholder="Write your name, purpose of the session, and any one of your social media identities; describe in at least 15 words and at most 75 words."
                minLength={90}
                maxLength={400}            
                // value={ContactData.message}
                onChange={handleInputChange}
              />
              </div>
              </>
            )}
            {purpose && purpose.length > 50 && (
              <>
              <div className={`container mt-5 ${styles.paymentContainer}`}>
                <h5 className='ml-2 mt-2'>Order Summary</h5>
                <table className="table">
  <tbody>
    <tr>
      <td>
        <p>
          <strong>Influencer Fee </strong>
          <span style={{ fontSize: "80%" }}>(including GST and Taxes)</span>
        </p>
      </td>
      <td>
        <p>₹{amountfee}</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong>Platform Fee </strong>
          <span style={{ fontSize: "80%" }}>(including 2.36% payment gateway charges)</span>
        </p>
      </td>
      <td>
        <p>₹{data.influencerdata.platformfee}</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong>GST on Platform Fee</strong>
          <span style={{ fontSize: "80%" }}>({9}% CGST and {9}% SGST)</span>
        </p>
      </td>
      <td>
        <p>₹{gstRate}</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong>Total Amount Payable </strong>
          <span style={{ fontSize: "80%" }}>(inclusive of all taxes)</span>
        </p>
      </td>
      <td>
        <p>₹{totalAmount}</p>
      </td>
    </tr>
  </tbody>
</table>
            
              </div>
              <span style={{fontSize:"80%",color:"grey"}}>Payment gateway charges 2.36% amounts to ₹{RazorpayCharge} </span> <br></br>
              <span style={{fontSize:"80%",color:"grey"}}>Platform Fee and GST on Platform Fee are non-refundable</span> 
              <div className="mt-3">
              <button
                className="btn btn-primary"
                onClick={handleProceedToPayment}
                disabled={!selectedSlot}
              >
                Proceed to Payment
              </button>
            </div>       
            </>  )}           
            <nav className="mt-3">
            <ul className="pagination">
            {Array.from({ length: Math.ceil(timeSlots.length / slotsPerPage) }, (_, i) => i + 1).map((pageNumber) => (
              <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                <button className="page-link ml-2" onClick={() => handlePageChange(pageNumber)}>
                  {pageNumber}
                </button>
              </li>
            ))}
          </ul>
            </nav>
          </>
        ) : (
          <p>Currently, no slots are available for booking.</p>
        )}
      </div>
      {alertData && (
        <div className="overlay">
          {/* You can add an overlay to dim the background if needed */}
          <AlertMessage
            message={alertData.message}
            type={alertData.type}
            onClose={() => setAlertData(null)}
          />
        </div>
      )}
    </>
  );
};

export default CheckAvailabilty;

import React,{useState} from 'react';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateClient } from '../../../services/auth-service/clientAccountService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';


const schema = yup.object().shape({
current_Password:yup.string().required(),
password:yup.string().required('Password is required')
.min(8, 'Password must contain atleast eight characters with one uppercase letter, one number and one special character')
.matches(
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  'Password must contain atleast eight characters with  one uppercase letter, one number and one special character'
),
cpassword:yup.string().oneOf([yup.ref('password'),null],'Password must match')}
)
const Changepassword = ({ initialValues }) => {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialValues);
    const [alertData, setAlertData] = React.useState(null);

    const {register,handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })
   

      const storedata = async(datainp,e) => {
        const datainpWithEmail = {
            ...datainp,
            email: values.email,
            _id:values._id
          };
        try {
        e.preventDefault();
        const res =await updateClient(datainpWithEmail);
        if(!res.status === 200){
            setAlertData({
              message: 'Failed to get Response',
            });
          }else if(res===404){
            setAlertData({
              message: 'No User found or no Changes made',
            });
          }else if(res===400){
            setAlertData({
              message: 'password not match',
            });
          }
          else{
            setAlertData({
              message: 'Updated!!',
            });
            setTimeout(() => {
              navigate('/logout');
            }, 2000); // Delay in milliseconds
          }
        // You can perform additional actions here, such as saving the updated values to a database.
       } catch (error) {
        console.log(error);
       }
      };

  return (
<>
<div className="inflanar-supports inflanar-profile-info mg-top-30">
<form onSubmit={handleSubmit(storedata)}>
<div className="row align-items-center">
<div className="col-lg-6 col-12">
<div className="inflanar-supports__password--form">

<div className="form-group inflanar-form-input">
<label>Current Password*</label>
<input className="inflanar-signin__form-input" 
placeholder="●●●●●●●●●●●●"  type="password" name="current_Password"  required="required" 
// id={`current_Password`}
// value={values.current_Password}
// onChange={(e) => handleChange( 'current_Password', e.target.value)} 
{...register('current_Password')}
/>
</div>
<div className="form-group inflanar-form-input mg-top-20">
<label>New Password*</label>
<input className="inflanar-signin__form-input" 
placeholder="●●●●●●●●●●●●"  type="password" name="password" required="required" 
// id={`password`}
// onChange={(e) => handleChange('password', e.target.value)}
{...register('password')}
/>
<p className='text-danger'>{errors.password?.message }</p>
</div>
<div className="form-group inflanar-form-input mg-top-20">
<label>Confirm Password*</label>
<input className="inflanar-signin__form-input" 
placeholder="●●●●●●●●●●●●"  type="password" name="confirmpassword" required="required" 
// id={`cpassword`}
// onChange={(e) => handleChange( 'cpassword', e.target.value)}
{...register('cpassword')}
/>
<p className='text-danger'>{errors.cpassword?.message }</p>

</div>
<div className="inflanar__item-button--group mg-top-50">
<button className="inflanar-btn" type="submit"><span>Update Password</span></button>
<button className="inflanar-btn inflanar-btn__cancel"><span>Cancel</span></button>
</div>

</div>
</div>
<div className="col-lg-6 col-md-6 col-12 d-none d-lg-block">
<div className="inflanar-signin__welcome 
inflanar-signin__welcome--password inflanar-bg-cover">
<img src="/img/in-account-cover.png" alt="#" style={{maxWidth:"355px"}}/>
</div>
</div>
<p className='mt-4 ml-4'>You will need to sign in again after successful password update</p>
</div>
</form>
</div>
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
  )
}

export default Changepassword
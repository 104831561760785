import React from 'react';
import styles from './Footer.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";


const Footer=()=> {
  return (
    <footer className="footer bg-dark text-white py-5">
    <div className="container">
      <div className={`row ${styles.row_content}`}>
        <div className="">
          <p style={{ color: "#7078a5"}}>Copyright&copy; {new Date().getFullYear().toString()},InKorero from Casaassure LLP. All rights reserved.</p>
        </div>
        <div className="pl-3 pr-3">
          <ul className="list-unstyled list-inline d-flex">
            <li className="list-inline-item"><Link className='text-white'  to="/terms-condition">Terms and Conditions</Link></li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item"><Link className='text-white' to="/privacyandpolicy">Privacy Policy</Link></li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item"><Link className='text-white' to="/cancellationpage">Refund Policy</Link></li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item"><Link className='text-white' to="/contactus">Contact Us</Link></li>
            <li className="list-inline-item">|</li>
            <li className="list-inline-item"><section className="mb-0">
            <FontAwesomeIcon
              className={`${styles.fbIcon} ml-2 ${styles.icons} rounded-circle`}
              icon={faFacebook}
              onClick={() => window.open('https://www.facebook.com/profile.php?id=61556260465127', '_blank')}            />
            <FontAwesomeIcon
              className={`${styles.twIcon} ml-2 ${styles.icons} rounded-circle`}
              icon={faXTwitter}
              onClick={() => window.open('https://twitter.com/InKorero', '_blank')} 
            />
            <FontAwesomeIcon
              className={`${styles.instaIcon} ml-2 ${styles.icons} rounded-circle`}
              icon={faInstagram}
              onClick={() => window.open('https://www.instagram.com/inkorero/', '_blank')}
            />
          </section></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;